export const lightColorPalette = {
  white: {
    400: "#FBFBFD",
    500: "#fff",
  },
  grey: {
    50: "#F5F7FA",
    100: "#EBECFC",
    300: "#828282",
    900: "#393956",
  },
  purple: {
    100: "#6a6a9f33",
    300: "#6A6A9F",
    400: "#8E8EBC",
    500: "#4251ED",
    700: "#2D369F",
  },
  red: {
    400: "#FF4F4F",
  },
  green: {
    500: "#0EBC15",
  },
};

export const lightPalette = {
  layout: {
    headerBg: lightColorPalette.white[500],
    contentBg: lightColorPalette.white[400],
  },
  app: {
    primaryText: lightColorPalette.grey[900],
    primaryColor: lightColorPalette.purple[500],
    secondaryText: lightColorPalette.purple[400],
    hoverColor: lightColorPalette.purple[700],
    placeholderColor: lightColorPalette.grey[300],
    cardItemBg: lightColorPalette.grey[50],
    inactiveDefaultBtnBorder: lightColorPalette.purple[300],
    selectBorder: lightColorPalette.purple[400],
    selectOptionBorder: lightColorPalette.purple[300],
    selectOptionActive: lightColorPalette.purple[100],
    modalFooterBorder: lightColorPalette.grey[100],
  },
};
