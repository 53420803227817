import { Menu, MenuProps } from "antd";
import { MenuInfo } from "rc-menu/lib/interface";
import { useLocation, useNavigate } from "react-router";

import { Icon } from "icons";

enum NavItems {
  QuestionAnswer = "/question-answer",
  Logs = "/logs",
}

const navItems: MenuProps["items"] = [
  {
    label: "Q/A",
    key: NavItems.QuestionAnswer,
    icon: <Icon icon="Question" />,
  },
  {
    label: "Logs",
    key: NavItems.Logs,
    icon: <Icon icon="FileClock" />,
  },
];

const DEFAULT_NAV_ITEM = NavItems.QuestionAnswer;

const NavMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getActiveNavItem = () =>
    navItems
      .find((item) => location.pathname.match(`${item?.key}/*`))
      ?.key?.toString() || DEFAULT_NAV_ITEM;

  const handleMenuItemClick = ({ key }: MenuInfo) => {
    navigate(key);
  };

  return (
    <Menu
      defaultSelectedKeys={[getActiveNavItem()]}
      items={navItems}
      onClick={handleMenuItemClick}
      mode="horizontal"
      className="nav-menu"
    />
  );
};

export default NavMenu;
