import { lazy as _lazy } from "react";

const lazyLoadIcon = (importFn: Function) =>
  _lazy(async () => {
    const icon = await importFn();
    return { default: icon.ReactComponent };
  });

export const iconsListing = {
  Logo: lazyLoadIcon(async () => import("./assets/agolo-logo.svg")),
  Question: lazyLoadIcon(async () => import("./assets/questions.svg")),
  QuestionCircled: lazyLoadIcon(
    async () => import("./assets/question-circled.svg")
  ),
  FileText: lazyLoadIcon(async () => import("./assets/file-text.svg")),
  Layers: lazyLoadIcon(async () => import("./assets/layers.svg")),
  Clock: lazyLoadIcon(async () => import("./assets/clock.svg")),
  Product: lazyLoadIcon(async () => import("./assets/product.svg")),
  ArrowDown: lazyLoadIcon(async () => import("./assets/arrow-down.svg")),
  CaretDown: lazyLoadIcon(async () => import("./assets/caret-down.svg")),
  InfoCircled: lazyLoadIcon(async () => import("./assets/info-circled.svg")),
  FileClock: lazyLoadIcon(async () => import("./assets/File Clock.svg")),
  Graph: lazyLoadIcon(async () => import("./assets/graph.svg")),
};
