import { LogsListingRequest } from "models/logs.model";

import { axiosConfig } from "./axios";
import { LOGS_LISTING_MOCK } from "./mocks";

export const fetchLogsAPI = async (request: LogsListingRequest) => {
  const url = `answers/v1/logs/search`;
  // const response = await axiosConfig("POST", url, { ...request });
  return new Promise<any>((resolve) => {
    setTimeout(() => {
      resolve({
        data: LOGS_LISTING_MOCK,
        statusText: "OK",
        status: 200,
      });
    }, 2000);
  });
};
